import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _typeof3 from "@babel/runtime/helpers/typeof";

var _typeof2 = "default" in _typeof3 ? _typeof3.default : _typeof3;

import _PropsDecoratorProvider2 from "./PropsDecoratorProvider";
import _createSubstyle2 from "./createSubstyle";
import _defaultPropsDecorator2 from "./defaultPropsDecorator";
import _inline2 from "./inline";
import _useStyles2 from "./useStyles";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _typeof = _typeof2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "PropsDecoratorProvider", {
  enumerable: true,
  get: function get() {
    return _PropsDecoratorProvider.default;
  }
});
Object.defineProperty(exports, "PropsDecoratorContext", {
  enumerable: true,
  get: function get() {
    return _PropsDecoratorProvider.PropsDecoratorContext;
  }
});
Object.defineProperty(exports, "createSubstyle", {
  enumerable: true,
  get: function get() {
    return _createSubstyle.default;
  }
});
Object.defineProperty(exports, "defaultPropsDecorator", {
  enumerable: true,
  get: function get() {
    return _defaultPropsDecorator.default;
  }
});
Object.defineProperty(exports, "inline", {
  enumerable: true,
  get: function get() {
    return _inline.default;
  }
});
exports.default = void 0;

var _PropsDecoratorProvider = _interopRequireWildcard(_PropsDecoratorProvider2);

var _createSubstyle = _interopRequireDefault(_createSubstyle2);

var _defaultPropsDecorator = _interopRequireDefault(_defaultPropsDecorator2);

var _inline = _interopRequireDefault(_inline2);

var _useStyles = _interopRequireDefault(_useStyles2);

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var _default = _useStyles.default;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule,
      PropsDecoratorProvider = exports.PropsDecoratorProvider,
      PropsDecoratorContext = exports.PropsDecoratorContext,
      createSubstyle = exports.createSubstyle,
      defaultPropsDecorator = exports.defaultPropsDecorator,
      inline = exports.inline;