import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _defineProperty3 from "@babel/runtime/helpers/defineProperty";

var _defineProperty = "default" in _defineProperty3 ? _defineProperty3.default : _defineProperty3;

import _pickStyles2 from "./pickStyles";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(_defineProperty);

var _pickStyles = _pickStyles2;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2.default)(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
} // many css-in-js libs process keyframes objects as the value for `animationName`


var defaultObjectPropsWhitelist = ["animationName"];

var defaultPropsDecorator = function defaultPropsDecorator(_ref) {
  var style = _ref.style,
      className = _ref.className;
  return _objectSpread(_objectSpread({}, style ? {
    style: (0, _pickStyles.pickDirectStyles)(style, defaultObjectPropsWhitelist)
  } : {}), className ? {
    className: className
  } : {});
};

var _default = defaultPropsDecorator;
exports.default = _default;
export default exports;