import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _react3 from "react";

var _react2 = "default" in _react3 ? _react3.default : _react3;

import _PropsDecoratorProvider2 from "./PropsDecoratorProvider";
import _createSubstyle2 from "./createSubstyle";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _react2;
var _PropsDecoratorProvider = _PropsDecoratorProvider2;

var _createSubstyle = _interopRequireDefault(_createSubstyle2);

var useStyles = function useStyles(defaultStyle, _ref, modifiers) {
  var style = _ref.style,
      className = _ref.className,
      classNames = _ref.classNames;
  var propsDecorator = (0, _react.useContext)(_PropsDecoratorProvider.PropsDecoratorContext);
  var substyle = (0, _react.useMemo)(function () {
    return (0, _createSubstyle.default)({
      style: style,
      className: className,
      classNames: classNames
    }, propsDecorator);
  }, [style, className, classNames, propsDecorator]);
  return substyle(modifiers, defaultStyle);
};

var _default = useStyles;
exports.default = _default;
export default exports;